// src/index.js
import React from 'react';
import ReactDOM from 'react-dom/client'; // Updated import for React 18
import App from './App';
//import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import './styles/custom.scss'; // Import custom SCSS
import 'bootstrap-icons/font/bootstrap-icons.css';
import 'bootstrap/dist/js/bootstrap.bundle.min'; // Import Bootstrap JS

// Use createRoot instead of ReactDOM.render
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);