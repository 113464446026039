// src/App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/Header'; // Shared Header component
import Home from './pages/Home'; // Home page component
import LinkCheck from './pages/LinkCheck'; // Link check functionality component

function App() {
  return (
    <Router>
      <Header /> {/* Header will be reused across routes */}
      <main className="container mt-4">
        <Routes>
          {/* Define routes for different parts of the app */}
          <Route path="/team" element={<Home />} /> {/* Main home page */}
          <Route path="/team/link-check" element={<LinkCheck />} /> {/* Link check page */}
        </Routes>
      </main>
    </Router>
  );
}

export default App;