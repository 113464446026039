import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Confetti from 'react-confetti'; // Import Confetti for celebration

const API_BASE_URL = process.env.NODE_ENV === 'development' ? '/api' : 'https://api.pacificmedicaltraining.com';

const LinkCheck = () => {
  const [links, setLinks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [reviewedCount, setReviewedCount] = useState(0); // Count of reviewed links
  const [showConfetti, setShowConfetti] = useState(false); // Confetti state

  useEffect(() => {
    fetchLinks();
  }, []);

  useEffect(() => {
    // Prefetch the URL at the top of the list whenever the list is updated
    if (links.length > 0) {
      prefetchUrl(links[0].url);
    }
  }, [links]); // Re-run this effect when 'links' changes

  const fetchLinks = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/link-check/unconfirmed-links`);
      setLinks(response.data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching links:', error);
      setLoading(false);
    }
  };

  const handleStatusUpdate = async (url, status, index) => {
    if (!window.confirm(`Are you sure you want to mark this link as ${status === 200 ? 'working' : 'removed'}?`)) {
      return; // Exit if user cancels
    }

    const updatedLinks = [...links];
    updatedLinks[index].loading = true; // Show loading spinner
    setLinks(updatedLinks);

    try {
      await axios.put(`${API_BASE_URL}/link-check/status?url=${encodeURIComponent(url)}&status=${status}`);
      console.log(`Updated ${url} to status ${status}`);

      setReviewedCount((prev) => prev + 1); // Increment reviewed count
      if ((reviewedCount + 1) % 10 === 0) {
        setShowConfetti(true); // Show confetti on multiples of 10
        setTimeout(() => setShowConfetti(false), 3000); // Hide confetti after 3 seconds
      }

      // Fade out the row after a delay
      setTimeout(() => {
        const newLinks = links.filter((link) => link.url !== url);
        setLinks(newLinks);
      }, 500); // Adjust the fade-out duration as needed
    } catch (error) {
      console.error('Error updating status:', error);
    }
  };

  const prefetchUrl = (url) => {
    const link = document.createElement('link');
    link.rel = 'prefetch';
    link.href = url;
    document.head.appendChild(link);
    console.log(`Prefetched URL: ${url}`);
  };

  const copyToClipboard = (url) => {
    navigator.clipboard.writeText(url).then(() => {
      console.log(`Copied ${url} to clipboard`);
    }).catch((error) => {
      console.error('Error copying to clipboard:', error);
    });
  };

  const getStatusColor = (statusCode) => {
    if (statusCode === 'Checking...') return 'yellow'; // New color for checking state
    if (statusCode >= 200 && statusCode < 300) return 'green';
    if (statusCode >= 300 && statusCode < 400) return 'blue';
    if (statusCode >= 400 && statusCode < 500) return 'orange';
    if (statusCode >= 500) return 'red';
    if (statusCode === 'Error') return 'red';
    return 'grey';
  };

  const renderRows = () => {
    return links.map((link, index) => (
      <tr
        key={link.id}
        style={{
          opacity: link.loading ? 0.5 : 1, // Fade out while loading
          transition: 'opacity 0.5s ease-in-out' // Smooth fade effect
        }}
      >
        <td>
          <button
            className="btn btn-info"
            onClick={() => handleStatusUpdate(link.url, 200, index)}
            disabled={link.loading}
          >
            {link.loading ? <span className="spinner-border spinner-border-sm" /> : 'Link works'}
          </button>
        </td>
        <td>
          <button
            className="btn btn-warning"
            onClick={() => handleStatusUpdate(link.url, 400, index)}
            disabled={link.loading}
          >
            {link.loading ? <span className="spinner-border spinner-border-sm" /> : 'I removed link'}
          </button>
        </td>
        <td>
          <button className="btn btn-secondary" onClick={() => copyToClipboard(link.url)}>Copy</button> {/* Styled as .btn.btn-secondary */}
        </td>
        <td>
          {link['redirect_to'] ? (
            <>
              <span role="img" aria-label="redirect" title="Redirects to another URL">🔀</span> {/* Redirect icon */}
              {' '}
            </>
          ) : null}
          {link.url}
        </td>
      </tr>
    ));
  };

  return (
    <div>
      {showConfetti && <Confetti />} {/* Confetti effect */}

      <h2>Broken links to review</h2>
      <p>When <a href="https://github.com/fulldecent/mtssites/actions">our continuous integration</a> runs for each website, broken links are reported here.</p>
      <p>Lines are removed when you manually confirm that the <strong>link works</strong> (and does not redirect) or that <strong>you removed the bad link</strong> from our websites.</p>
      <p>After 180 days, all manual reviews are reset.</p>

      <p>You reviewed {reviewedCount} links</p> {/* Progress note */}

      {loading ? (
        <p>Loading...</p>
      ) : (
        <table className="table table-bordered">
          <thead>
            <tr>
              <th>Good</th>
              <th>Removed</th>
              <th>Copy</th>
              <th>URL</th>
            </tr>
          </thead>
          <tbody>{renderRows()}</tbody>
        </table>
      )}
    </div>
  );
};

export default LinkCheck;
