// src/pages/Home.js
import React from 'react';

const Home = () => {
  return (
    <div>
      <h2>Welcome to PMT team page</h2>
      <p>This is the home page content styled with Bootstrap.</p>
    </div>
  );
};

export default Home;