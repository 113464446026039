// src/components/Header.js
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

const Header = () => {
  // Function to get the user's preferred theme from local storage or system preference
  const getPreferredTheme = () => {
    const storedTheme = localStorage.getItem('theme');
    if (storedTheme) {
      return storedTheme;
    }
    return window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light';
  };

  // State to track the current theme mode
  const [theme, setTheme] = useState(getPreferredTheme);

  // Apply the theme to the HTML element
  useEffect(() => {
    const applyTheme = (currentTheme) => {
      if (currentTheme === 'auto') {
        const systemTheme = window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light';
        document.documentElement.setAttribute('data-bs-theme', systemTheme);
      } else {
        document.documentElement.setAttribute('data-bs-theme', currentTheme);
      }
      localStorage.setItem('theme', currentTheme);
    };

    applyTheme(theme);

    // Listen for changes in system theme preference when in 'auto' mode
    const handleSystemThemeChange = (e) => {
      if (theme === 'auto') {
        const newSystemTheme = e.matches ? 'dark' : 'light';
        document.documentElement.setAttribute('data-bs-theme', newSystemTheme);
      }
    };

    const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    mediaQuery.addEventListener('change', handleSystemThemeChange);

    return () => {
      mediaQuery.removeEventListener('change', handleSystemThemeChange);
    };
  }, [theme]);

  // Handle theme change
  const handleThemeChange = (newTheme) => {
    setTheme(newTheme);
  };

  return (
    <header>
      <nav className="navbar navbar-expand-lg border-bottom mb-4 bg-primary navbar-dark">
        <div className="container">
          <Link className="navbar-brand" to="/">
            <img
              src={`${process.env.PUBLIC_URL}/logo192.png`}
              alt="logo"
              width="30"
              height="30"
              className="d-inline-block align-top me-1"
            />
            <strong>Team page</strong>
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarNav">
            <ul className="navbar-nav">
              <li className="nav-item">
                <Link className="nav-link" to="/team">
                  Home
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/team/link-check">
                  Link check
                </Link>
              </li>
            </ul>

            {/* Theme Dropdown */}
            <div className="dropdown ms-auto">
              <button
                className="btn btn-outline-secondary dropdown-toggle"
                type="button"
                id="themeDropdown"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <i className={`bi ${theme === 'dark' ? 'bi-moon-fill' : theme === 'light' ? 'bi-sun-fill' : 'bi-circle-half'}`}></i>
              </button>
              <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="themeDropdown">
                <li>
                  <button className="dropdown-item" onClick={() => handleThemeChange('light')}>
                    <i className="bi bi-sun-fill"></i> Light mode
                  </button>
                </li>
                <li>
                  <button className="dropdown-item" onClick={() => handleThemeChange('dark')}>
                    <i className="bi bi-moon-fill"></i> Dark mode
                  </button>
                </li>
                <li>
                  <button className="dropdown-item" onClick={() => handleThemeChange('auto')}>
                    <i className="bi bi-circle-half"></i> Auto (system)
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Header;